import Vue from "vue"
const requireComponent = require.context("./base", false, /[\w]+\.vue$/)

requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName)

    const componentName = fileName.replace(/\.\//, "").replace(/\.vue/, "")

    Vue.component(componentName, componentConfig.default || componentConfig)
})
