<template>
    <div>
        <div class="container">
            <h1 class="page-title">{{ $t("title") }}</h1>
        </div>

        <div class="container">
            <div class="category-box">
                <a class="category-link" c href="#buy"
                    ><svg
                        class="icon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M19 9L12 16L5 9"
                            stroke="#34cdd7"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    {{ $t("buy_title") }}</a
                >
                <span class="separator" />
                <a class="category-link" href="#use">
                    <svg
                        class="icon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M19 9L12 16L5 9"
                            stroke="#34cdd7"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        /></svg
                    >{{ $t("use_title") }}</a
                >
            </div>
        </div>

        <div id="buy" class="container">
            <h2 class="section-title mt-lg">{{ $t("buy_title") }}</h2>
        </div>

        <div class="container ">
            <div class="media-flex-wrap pt-xs">
                <div class="media-flex">
                    <div class="media-flex-item copy">
                        <h3 class="section-title section-title-sm">
                            <span class="ts-brand">1</span>
                            {{ $t("steps.1.title") }}
                        </h3>
                        <p class="ts-quiet">
                            {{ $t("steps.1.description") }}
                            <a :href="$t('link.app')" target="_blank" rel="noopener noreferrer">
                                <img
                                    class="app-store-badge"
                                    src="@/assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
                                />
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <hr />

        <div class="container">
            <div class="media-flex-wrap">
                <div class="media-flex">
                    <div class="media-flex-item copy">
                        <h3 class="section-title section-title-sm">
                            <span class="ts-brand">2</span>
                            {{ $t("steps.2.title") }}
                        </h3>
                        <p>{{ $t("steps.2.description") }}</p>
                    </div>
                    <div class="media-flex-item image">
                        <img :src="img1[$i18n.locale]" />
                    </div>
                </div>
            </div>
        </div>

        <hr />

        <div class="container">
            <div class="media-flex-wrap">
                <div class="media-flex">
                    <div class="media-flex-item copy">
                        <h3 class="section-title section-title-sm">
                            <span class="ts-brand">3</span>
                            {{ $t("steps.3.title") }}
                        </h3>
                        <p>{{ $t("steps.3.description") }}</p>
                    </div>
                    <div class="media-flex-item image">
                        <img :src="img2[$i18n.locale]" />
                    </div>
                </div>
            </div>
        </div>

        <hr />

        <div class="container">
            <div class="media-flex-wrap">
                <div class="media-flex">
                    <div class="media-flex-item copy">
                        <h3 class="section-title section-title-sm">
                            <span class="ts-brand">4</span>
                            {{ $t("steps.4.title") }}
                        </h3>
                        <p>{{ $t("steps.4.description") }}</p>
                    </div>
                    <div class="media-flex-item image">
                        <img :src="img3[$i18n.locale]" />
                    </div>
                </div>
            </div>
        </div>

        <hr />

        <div id="use" class="container">
            <h2 class="section-title mt-lg">{{ $t("use_title") }}</h2>
        </div>

        <div class="container mb-md">
            <div class="media-flex-wrap">
                <div class="media-flex">
                    <div class="media-flex-item copy">
                        <h3 class="section-title section-title-sm pl-md">
                            {{ $t("use_step.title") }}
                        </h3>
                        <p>{{ $t("use_step.description") }}</p>
                    </div>
                    <div class="media-flex-item image mt-sm">
                        <img :src="img4[$i18n.locale]" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import img1en from "@/assets/images/gs-img-1_en.png"
import img1ja from "@/assets/images/gs-img-1_ja.png"
import img2en from "@/assets/images/gs-img-2_en.png"
import img2ja from "@/assets/images/gs-img-2_ja.png"
import img3en from "@/assets/images/gs-img-3_en.png"
import img3ja from "@/assets/images/gs-img-3_ja.png"
import img4en from "@/assets/images/gs-img-4_en.png"
import img4ja from "@/assets/images/gs-img-4_ja.png"

export default {
    meta() {
        return this.$generateMeta({
            title: this.$i18n.t("meta.title"),
            description: this.$i18n.t("meta.description"),
        })
    },
    data() {
        return {
            img1: {
                en: img1en,
                ja: img1ja,
            },
            img2: {
                en: img2en,
                ja: img2ja,
            },
            img3: {
                en: img3en,
                ja: img3ja,
            },
            img4: {
                en: img4en,
                ja: img4ja,
            },
        }
    },

    i18n: {
        messages: {
            en: {
                meta: {
                    title: "Getting Started",
                    description:
                        "This page explains how to use Soracom Mobile eSIM step by step. From buying a data plan to installing eSIM profile to your device, we cover them all in details.",
                },
                title: "Getting Started",
                buy_title: "Buying an eSIM",
                steps: {
                    1: {
                        title: "Download the app.",
                        description: `Download "Soracom Mobile" from the App Store. There is no cost to download, and you'll only be asked to create an account when you're ready to activate your first eSIM.`,
                    },
                    2: {
                        title: "Choose an eSIM",
                        description: `Browse the in-app store to select a region and data plan you need.`,
                    },
                    3: {
                        title: "Pay with Apple Pay or Credit Card.",
                        description: `In the Store tab, enter your preferred payment source.`,
                    },
                    4: {
                        title: "Let the app install your eSIM profile automatically.",
                        description: `You don't need to set anything else.`,
                    },
                },
                use_title: "Using an eSIM",
                use_step: {
                    title: "Set Soracom Mobile eSIM for Cellular Data",
                    description:
                        "Go to Settings > Mobile Data and tap on Cellular Data, and select Soracom Mobile eSIM. If it's not displayed as an option, enable 'Turn On This Line' under SIM section.",
                },
            },
            ja: {
                meta: {
                    title: "eSIM利用の流れ",
                    description:
                        "ソラコム モバイルのeSIMの使い方を紹介します。データプランの購入からeSIMプロファイルのインストール、現地での設定までステップごとに解説します。",
                },
                title: "eSIM利用の流れ",
                buy_title: "eSIMの購入方法",
                steps: {
                    1: {
                        title: "アプリをダウンロード",
                        description: `App Storeで"Soracom Mobile”と検索し、アプリをダウンロードしてください。アプリは無料で、アカウント作成は最初のeSIMを購入するときまで必要ありません。`,
                    },
                    2: {
                        title: "eSIMを選択",
                        description: `アプリ内のStoreから地域とeSIMプランを選びます。`,
                    },
                    3: {
                        title: "Apple Payまたはクレジットカードで購入",
                        description: `好みの支払い方法を選んでください。`,
                    },
                    4: {
                        title: "アプリ上でeSIMをインストール",
                        description: `他に必要な設定は一切ありません。`,
                    },
                },
                use_title: "現地での利用方法",
                use_step: {
                    title: "モバイルデータ通信でソラコムモバイルを選択",
                    description:
                        "設定 > モバイル通信 > モバイルデータ通信をタップし、ソラコムモバイルのeSIMを割り当てます。尚、選択肢として表示されない場合は、SIMのセクションで該当回線を選択し、「この回線をオンにする」を有効にしてください。",
                },
            },
        },
    },
}
</script>

<style>
.category-box {
    display: flex;
    align-items: center;
    font-size: 16px;
}
.mt-sm {
    margin-top: 20px;
}
.mt-md {
    margin-top: 40px;
}
.mt-lg {
    margin-top: 80px;
}
.mb-md {
    margin-bottom: 40px;
}
.pl-md {
    padding-left: 40px;
}
.pt-xs {
    padding-top: 20px;
}
</style>
