export default {
    install(Vue, options) {
        Vue.directive("click-outside", {
            bind: function(el, binding, vnode) {
                window.event = function(event) {
                    if (!(el == event.target || el.contains(event.target))) {
                        vnode.context[binding.expression](event)
                    }
                }
                document.body.addEventListener("mousedown", window.event)
            },
            unbind: function(el) {
                document.body.removeEventListener("mousedown", window.event)
            },
        })
    },
}
