<template>
    <div>
        <div @click="open">
            <slot name="activator"></slot>
        </div>
        <div class="menu">
            <BaseExpandTransition>
                <div v-if="value" v-click-outside="close" :style="styles" class="content">
                    <slot></slot>
                    <div class="ts-align-right">
                        <div @click="close">
                            <slot name="close"></slot>
                        </div>
                    </div>
                </div>
            </BaseExpandTransition>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: { type: Boolean },
        classes: { type: String },
        right: { type: Boolean, default: false },
    },
    data() {
        return {
            ts: 0,
        }
    },

    computed: {
        styles() {
            if (this.right) {
                return { right: 0 }
            } else {
                return { left: 0 }
            }
        },
    },
    methods: {
        open() {
            this.change(true)
        },
        toggle() {
            this.change(!this.value)
        },
        close() {
            if (this.value) {
                this.change(false)
            }
        },
        change(value) {
            if (Date.now() - this.ts > 200) {
                this.$emit("input", value)
            }
            this.ts = Date.now()
        },
    },
}
</script>

<style lang="scss" scoped>
.menu {
    position: relative;
    width: 100%;
    .content {
        top: 3px;
        position: absolute;
        z-index: 10;
        background-color: white;
    }
}
</style>
