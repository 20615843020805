<template>
    <div
        :class="[
            'input-group',
            { 'form-error': error },
            { small: small },
            { 'form-success': success },
            { 'form-warning': warning },
        ]"
    >
        <label :class="['form-label', { disabled }, { small: small }]" :for="name + formName">{{
            label
        }}</label>
        <input
            :class="['textfield', 'input', { filled: value }]"
            ref="input"
            :name="name"
            :id="name + formName"
            v-bind="$attrs"
            :value="value"
            v-on="listeners"
            v-if="$attrs.type != 'textarea'"
            :disabled="disabled"
        />

        <textarea
            :class="['textarea', { filled: value }]"
            ref="input"
            v-bind="$attrs"
            :name="name"
            :id="name + formName"
            :value="value"
            v-else
            v-on="listeners"
            :disabled="disabled"
        ></textarea>

        <div v-if="typeof error === 'object'">
            <div class="form-error" v-for="e in error" :key="e" v-text="e"></div>
        </div>
        <div class="helper-block error" v-text="error" v-else-if="typeof error === 'string'"></div>
        <div class="helper-block warning" v-if="warning" v-text="warning"></div>
        <div class="helper-block">{{ helper }}</div>
    </div>
</template>

<script>
export default {
    props: {
        value: {},
        name: { type: String, required: false, default: "default" },
        formName: { type: String, required: false, default: "" },
        label: { type: String, required: false },
        helper: { type: String, required: false },
        disabled: { type: Boolean, required: false },
        small: { type: Boolean, required: false },
        success: { type: Boolean, required: false },
        warning: { type: Boolean | String, required: false, default: false },
        error: { type: String | Boolean, required: false, default: null },
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                input: event => {
                    if (!this.disabled) {
                        this.$emit("input", event.target.value)
                    }
                },
            }
        },
    },
    inheritAttrs: false,
}
</script>
