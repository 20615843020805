<template>
    <div id="app" :class="['site-wrapper', $route.meta.originalName]">
        <Header />
        <div class="site-top-filigram">
            <img width="400" src="@/assets/images/filigram.png" />
        </div>
        <main class="main" aria-label="Content">
            <router-view />
        </main>
        <Footer />
        <CookieBanner />
    </div>
</template>

<script>
import Header from "@/components/Header"
import Footer from "@/components/Footer"
import CookieBanner from "@/components/CookieBanner"
import { mapActions } from "vuex"

export default {
    components: { Header, Footer, CookieBanner },

    meta() {
        return {
            titleTemplate: titleChunk => {
                return titleChunk
                    ? `${titleChunk} | ${this.$t("meta.default.short")}`
                    : this.$t("meta.default.middle")
            },
        }
    },
    async mounted() {
        this.loadCountries()
        this.loadPlans()
    },
    methods: {
        ...mapActions({
            loadCountries: "data/loadTopCountries",
            loadPlans: "data/loadPlans",
        }),
    },
    watch: {
        "$i18n.locale": {
            handler() {
                this.loadCountries()
                this.loadPlans()
            },
        },
    },
}
</script>
