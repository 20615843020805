if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.register(`${process.env.BASE_URL}service-worker.js`).then(reg => {
            reg.addEventListener("updatefound", () => {
                const newWorker = reg.installing
                newWorker.addEventListener("statechange", () => {
                    if (newWorker.state === "installed") {
                        if (navigator.serviceWorker.controller) {
                            window.location.reload()
                        }
                    }
                })
            })
        })
    }
}
