import { humanizeData, formatPrice, timeZone } from "../utils"
export default {
    install(Vue, options) {
        Vue.filter("humanizeData", humanizeData)
        Vue.filter("formatPrice", formatPrice)
        Vue.filter("timeZone", timeZone)
        Vue.filter("nl2br", val => {
            if (!val) {
                return ""
            }
            return val.split("\n").reduce((accumulator, string) => {
                if (!Array.isArray(accumulator)) {
                    return [accumulator, "<br>", string].join("")
                }
                return accumulator.concat(["<br>", string]).join("")
            })
        })
        Vue.filter("dateFormat", (date, small = true) => {
            console.log(date)
            const options = {
                year: "numeric",
                month: "short",
                day: "numeric",
            }
            if (!small) {
                options.hour = "numeric"
                options.minute = "numeric"
            }
            return new Date(date).toLocaleDateString(navigator.language, options)
        })
    },
}
