import Vue from "vue"
import Router from "vue-router"
import Home from "./views/Home.vue"
import Pricing from "./views/Pricing.vue"
import GettingStarted from "./views/GettingStarted.vue"
import store from "./store"
import { i18n } from "./plugins/i18n"
Vue.use(Router)

const routes = [
    {
        path: "/",
        name: "home",
        component: Home,

        pathToRegexpOptions: { strict: true },
    },
    {
        path: "/pricing",
        name: "pricing",
        component: Pricing,
        pathToRegexpOptions: { strict: true },
    },
    {
        path: "/faq",
        name: "faq",
        component: () => import(/* webpackChunkName: "faq" */ "./views/Faq.vue"),
        pathToRegexpOptions: { strict: true },
    },
    {
        path: "/getting-started",
        name: "getting-started",
        component: GettingStarted,
        pathToRegexpOptions: { strict: true },
    },
    {
        path: "/contact/",
        name: "contact",
        component: () => import(/* webpackChunkName: "contact" */ "./views/Contact.vue"),
    },
    {
        path: "/terms/",
        name: "terms",
        component: () => import(/* webpackChunkName: "terms" */ "./views/Terms.vue"),
    },
    {
        path: "/terms/privacy/",
        name: "privacy",
        component: () => import(/* webpackChunkName: "privacy" */ "./views/Privacy.vue"),
    },
    {
        path: "/compatibility",
        name: "compatibility",
        component: () =>
            import(/* webpackChunkName: "compatibility" */ "./views/Compatibility.vue"),
        pathToRegexpOptions: { strict: true },
    },
    {
        path: "/cookie-policy/",
        name: "cookie-policy",
        component: () => import(/* webpackChunkName: "cookie-policy" */ "./views/CookiePolicy.vue"),
    },
    {
        path: "/sct",
        name: "sct",
        component: () => import(/* webpackChunkName: "sct" */ "./views/Sct.vue"),
        pathToRegexpOptions: { strict: true },
    },
]

const locales = i18n.availableLocales

let fullRoutes = []
routes.forEach(r => {
    locales.forEach(l => {
        if (l === "en") {
            fullRoutes.push({
                meta: { locale: l, originalName: r.name },
                name: `${r.name}_${l}`,
                path: `${r.path}`,
                component: r.component,
            })
            return
        }

        fullRoutes.push({
            meta: { locale: l, originalName: r.name },
            name: `${r.name}_${l}`,
            path: r.path === "/" ? `/${l}/` : `/${l}${r.path}`,
            component: r.component,
        })
    })
})
fullRoutes.push({
    path: "*",
    component: () => import(/* webpackChunkName: "404" */ "./views/NotFound.vue"),
})
export const router = new Router({
    mode: "history",
    base: "/",
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
    routes: fullRoutes,
})
router.beforeEach((to, from, next) => {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.getRegistration().then(reg => {
            if (reg) {
                reg.update()
            }
        })
    }

    if (!to.meta.locale) {
        i18n.locale = to.path.match(/^\/ja.*/) ? "ja" : "en"
        document.getElementsByTagName("html")[0].lang = i18n.locale
        return next()
    }
    if (i18n.locale !== to.meta.locale) {
        i18n.locale = to.meta.locale
        document.getElementsByTagName("html")[0].lang = i18n.locale
    }
    return next()
})

router.afterEach(to => {
    if (to.hash && to.hash.match(/^#/)) {
        setTimeout(() => {
            document.getElementById(to.hash.replace(/^#/, "")).scrollIntoView()
        }, 150)
    }
    setTimeout(() => {
        document.dispatchEvent(new Event("custom-render-trigger"))
    }, 1000)
})

export const resolve = route => {
    const to = router.resolve(route)
    return to.resolved.path.replace(/\/$/, "") + "/" + to.resolved.hash
}
