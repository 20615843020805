<template>
    <div>
        <div @click="toggle">
            <slot name="activator"></slot>
        </div>
        <BaseExpandTransition>
            <div v-if="value || active" :class="classes">
                <slot></slot>
                <div class="ts-align-right">
                    <div @click="close">
                        <slot name="close"></slot>
                    </div>
                </div>
            </div>
        </BaseExpandTransition>
    </div>
</template>

<script>
export default {
    props: {
        value: { type: Boolean, default: null },
        right: { type: Boolean, default: false },
        classes: {},
    },
    inheritAttrs: true,
    data() {
        return {
            ts: 0,
            active: false,
        }
    },
    watch: {
        value(value) {
            this.ts = Date.now()
        },
        active() {
            this.ts = Date.now()
        },
    },
    methods: {
        toggle() {
            if (this.active || this.value) {
                return this.close()
            }
            this.open()
        },
        open() {
            this.active = true
            this.$emit("input", true)
        },
        close() {
            if (Date.now() - this.ts > 100) {
                this.active = false
                this.$emit("input", false)
            }
        },
    },
}
</script>
