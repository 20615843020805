import Vue from "vue"
import App from "./App.vue"
import { router } from "./router"
import store from "./store"
import { i18n } from "./plugins/i18n"
import generateMeta from "./plugins/generate-meta"
import Lazyload from "./plugins/lazyload"
import Filters from "./plugins/filters"
import ClickOutside from "./plugins/click-outside"
import VueMeta from "vue-meta"
import VueGtag from "vue-gtag"
import "./registerServiceWorker"
import "./components/_globals"
import "./assets/styles/styles.scss"

Vue.use(VueMeta, {
    keyName: "meta",
})

if (process.env.NODE_ENV === "production") {
    Vue.use(
        VueGtag,
        {
            config: {
                id: "G-9PTSGT4Y3P",
            },
        },
        router
    )
}

Vue.use(generateMeta)
Vue.use(Lazyload)
Vue.use(Filters)
Vue.use(ClickOutside)
Vue.config.productionTip = false
Vue.prototype.$apiURL = process.env.VUE_APP_API_BASE_URL
window.addEventListener("beforeinstallprompt", (e) => {
    e.preventDefault()
})
const app = new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount("#app")
