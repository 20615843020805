<template>
    <router-link :to="i18nTo">
        <slot />
    </router-link>
</template>

<script>
import { resolve } from "@/router.js"
export default {
    props: {
        to: { type: Object },
        locale: { type: String },
    },
    computed: {
        i18nTo() {
            return resolve({
                ...this.to,
                name: this.to.locale
                    ? `${this.to.name}_${this.to.locale}`
                    : `${this.to.name}_${this.$i18n.locale}`,
            })
        },
    },
}
</script>
