<template>
    <button v-bind="$attrs" v-on="listeners" :disabled="loading">
        <slot></slot>
        <BaseLoader class="ml-1" v-if="loading" />
    </button>
</template>

<script>
export default {
    props: {
        loading: { type: Boolean, default: false },
    },
}
</script>

<style></style>template
