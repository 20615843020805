<template>
    <div>
        <div class="container">
            <h1 class="page-title">{{ $t("title") }}</h1>

            <div class="category-box">
                <a class="category-link" c href="#regions"
                    ><svg
                        class="icon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M19 9L12 16L5 9"
                            stroke="#34cdd7"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    {{ $t("regions_title") }}</a
                >
                <span class="separator" />
                <a class="category-link" href="#countries">
                    <svg
                        class="icon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M19 9L12 16L5 9"
                            stroke="#34cdd7"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        /></svg
                    >{{ $t("countries_title") }}</a
                >
            </div>
        </div>
        <!-- regions -->
        <template v-for="(group, index) in regions">
            <section id="regions" class="section" :key="group.slug">
                <div class="container">
                    <h2 class="section-title">{{ group.name }}</h2>
                    <button class="plans-list-coverage modal-trigger" @click="openModal(group)">
                        <span :key="`emoji-${c.code}`" v-for="c in group.countries.slice(0, 6)">{{
                            c.emoji_flag
                        }}</span>

                        <span class="plus" v-if="group.countries.length - 6 > 0">
                            {{ $tc("label.plus_n_more_countries", group.countries.length - 6) }}
                        </span>
                        <span class="plus" v-else>
                            {{ $tc("label.details") }}
                        </span>
                    </button>
                    <div class="plans-list">
                        <div class="plans-list-item" v-for="p in group.plans" :key="p.id">
                            <div class="card">
                                <span class="ts-quiet"
                                    >{{ p.capacity | humanizeData }} /
                                    {{ $tc("label.days", p.period) }}</span
                                >
                                <span class="price">{{ p.price | formatPrice }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <hr :key="group.slug + 'hr'" v-if="index + 2 <= plans.length" />
        </template>
        <!-- countries -->
        <template v-for="(group, index) in countries">
            <section id="countries" class="section" :key="group.slug">
                <div class="container">
                    <h2 class="section-title">{{ group.name }}</h2>
                    <button class="plans-list-coverage modal-trigger" @click="openModal(group)">
                        <span :key="`emoji-${c.code}`" v-for="c in group.countries.slice(0, 6)">{{
                            c.emoji_flag
                        }}</span>

                        <span class="plus" v-if="group.countries.length - 6 > 0">
                            {{ $tc("label.plus_n_more_countries", group.countries.length - 6) }}
                        </span>
                        <span class="plus" v-else>
                            {{ $tc("label.details") }}
                        </span>
                    </button>
                    <div class="plans-list">
                        <div class="plans-list-item" v-for="p in group.plans" :key="p.id">
                            <div class="card">
                                <span class="ts-quiet"
                                    >{{ p.capacity | humanizeData }} /
                                    {{ $tc("label.days", p.period) }}</span
                                >
                                <span class="price">{{ p.price | formatPrice }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <hr :key="group.slug + 'hr'" v-if="index + 2 <= plans.length" />
        </template>
        <BaseModal :title="$t('coverage_network')" :isOpen.sync="modalOpen">
            <div class="list-stacked" slot="body" v-if="modalOpen">
                <div
                    class="list-stacked-item"
                    v-for="c in detailCoverage.countries"
                    :key="`detail-${c.code}`"
                >
                    <div class="list-stacked-item-body">
                        <strong>{{ c.emoji_flag }} {{ c.name }}</strong>
                    </div>
                    <div class="list-stacked-item-side">
                        <span class="ts-quiet">{{ displayNetwork(c.networks) }}</span>
                    </div>
                    <div
                        v-if="c.info"
                        class="ts-quiet ts-small"
                        v-html="$options.filters.nl2br(c.info)"
                    ></div>
                </div>
            </div>
        </BaseModal>
    </div>
</template>

<script>
import Vue from "vue"
import { mapActions, mapGetters } from "vuex"
export default {
    meta() {
        return this.$generateMeta({
            title: this.$i18n.t("meta.title"),
            description: this.$i18n.t("meta.description"),
        })
    },

    i18n: {
        messages: {
            en: {
                meta: {
                    title: "Pricing",
                    description:
                        "Soracom Mobile 4G/LTE/3G Prepaid eSIM for Europe, North America, and Oceania. Covers UK, France, Spain, Italy, Germany, Netherlands, Sweden, USA, Canada, Australia, etc.",
                },
                title: "Our eSIM Prices",
                label: {
                    plus_n_more_countries:
                        "| + {n} country Coverage Details | + {n} countries Coverage Details",
                    details: "Coverage Details",
                },
                coverage_network: "Coverage & Network",
                regions_title: "Regions",
                countries_title: "Countries",
            },
            ja: {
                meta: {
                    title: "利用料金",
                    description:
                        "ソラコム モバイルのeSIMはヨーロッパ、北米、オセアニアで利用可能。対応国はイギリス、フランス、スペイン、イタリア、ドイツ、オランダ、スウェーデン、アメリア、カナダ、オーストラリアなど",
                },
                title: "eSIM 料金",
                label: {
                    plus_n_more_countries: "| + {n} ヵ国 カバレッジ | + {n} ヵ国 カバレッジ",
                    details: "カバレッジ",
                },
                coverage_network: "Coverage & Network",
                regions_title: "地域eSIM",
                countries_title: "国別eSIM",
            },
        },
    },

    data() {
        return {
            modalOpen: false,
            detailCoverage: null,
        }
    },

    computed: {
        regions() {
            return this.plans.filter(p => p.regions)
        },
        countries() {
            return this.plans.filter(p => !p.regions)
        },
        ...mapGetters({ plans: "data/plans" }),
    },
    methods: {
        openModal(group) {
            this.modalOpen = true
            this.detailCoverage = group
        },
        displayNetwork(ns) {
            return ns
                .map(n => {
                    return `${n.name} (${[
                        ...(n["2g"] && !n["3g"] ? ["2G"] : []),
                        ...(n["3g"] ? ["3G"] : []),
                        ...(n["4g"] ? ["4G/LTE"] : []),
                        ...(n["5g"] ? ["5G"] : []),
                    ].join(", ")})`
                })
                .join(", ")
        },
    },
}
</script>

<style>
.category-box {
    display: flex;
    align-items: center;
    font-size: 16px;
}

.separator {
    width: 5px;
    height: 5px;
    border-radius: 10px;
    background-color: #d8d8d8;
    display: inline-block;
    margin-right: 10px;
}

.category-link {
    margin-right: 10px;
}

.icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
}

.modal-trigger {
    border: none;
    color: #34cdd7;
    cursor: pointer;
}
</style>
