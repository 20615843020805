<template>
    <div>
        <div class="hero">
            <img class="hero-device" width="400" :src="appImage[$i18n.locale]" />

            <div class="container">
                <div class="hero-copy">
                    <div class="ts-measure">
                        <h1 class="page-title" v-html="$t('title')" />
                        <p class="page-title-support ts-quiet">{{ $t("main_plot") }}</p>
                        <div class="block-medium">
                            <a :href="$t('link.app')" target="_blank" rel="noopener noreferrer">
                                <img
                                    class="app-store-badge"
                                    src="@/assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="section">
            <div class="container">
                <div class="card">
                    <h3 class="section-title">{{ $t("how_it_works.title") }}</h3>
                    <div class="card-block">
                        <div class="stepper">
                            <div class="stepper-header">
                                <div class="stepper-item">
                                    <span class="stepper-number">1</span>
                                </div>
                                <div class="stepper-item">
                                    <span class="stepper-number">2</span>
                                </div>
                                <div class="stepper-item">
                                    <span class="stepper-number">3</span>
                                </div>
                                <div class="stepper-item">
                                    <span class="stepper-number">4</span>
                                </div>
                            </div>
                            <div class="stepper-body">
                                <div class="stepper-item">
                                    <h4>
                                        <span class="stepper-number-mobile">1.</span>
                                        {{ $t("how_it_works.steps.1.title") }}
                                    </h4>
                                    <p class="ts-quiet">
                                        {{ $t("how_it_works.steps.1.description") }}
                                    </p>
                                </div>
                                <div class="stepper-item">
                                    <h4>
                                        <span class="stepper-number-mobile">2.</span>
                                        {{ $t("how_it_works.steps.2.title") }}
                                    </h4>
                                    <p class="ts-quiet">
                                        {{ $t("how_it_works.steps.2.description") }}
                                    </p>
                                </div>
                                <div class="stepper-item">
                                    <h4>
                                        <span class="stepper-number-mobile">3.</span>
                                        {{ $t("how_it_works.steps.3.title") }}
                                    </h4>
                                    <p class="ts-quiet">
                                        {{ $t("how_it_works.steps.3.description") }}
                                    </p>
                                </div>

                                <div class="stepper-item">
                                    <h4>
                                        <span class="stepper-number-mobile">4.</span>
                                        {{ $t("how_it_works.steps.4.title") }}
                                    </h4>
                                    <p class="ts-quiet">
                                        {{ $t("how_it_works.steps.4.description") }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="wall">
                <div class="wall-copy">
                    <h2 class="section-title">{{ $t("coverage_info.title") }}</h2>
                    <p class="ts-quiet">{{ $t("coverage_info.description") }}</p>
                    <div class="block-medium">
                        <i18n-link class="button normal-case" :to="{ name: 'pricing' }">
                            {{ $t("coverage_info.view_full_list") }}
                        </i18n-link>
                    </div>
                </div>
                <div class="wall-picture">
                    <div class="wall-picture-wrap">
                        <img src="@/assets/images/travel_pic_1.jpg" />
                    </div>
                </div>
            </div>
        </section>

        <section class="section">
            <div class="slider">
                <div
                    class="slider-row"
                    v-for="(row, index) in countryLines"
                    :key="`row-index-${index}`"
                >
                    <div class="slider-item" v-for="c in row" :key="c.slug">
                        <span>{{ c.emoji_flag }}</span>
                        <span>{{ c.name }}</span>
                    </div>
                </div>
            </div>
        </section>

        <section class="section">
            <div class="wall">
                <div class="wall-picture">
                    <div class="wall-picture-wrap">
                        <img src="@/assets/images/travel_pic_2.jpg" />
                    </div>
                </div>
                <div class="wall-copy">
                    <h2 class="section-title">{{ $t("all_you_need.title") }}</h2>
                    <p class="ts-quiet">{{ $t("all_you_need.description") }}</p>
                    <div class="block-medium">
                        <i18n-link class="button" :to="{ name: 'getting-started' }">
                            {{ $t("all_you_need.view_our_plans") }}
                        </i18n-link>
                    </div>
                </div>
            </div>
        </section>

        <section class="section">
            <div class="wall">
                <div class="wall-copy">
                    <h2 class="section-title">{{ $t("reason_to_use.title") }}</h2>
                    <div class="block-medium">
                        <a :href="$t('link.app')" target="_blank" rel="noopener noreferrer">
                            <img
                                class="app-store-badge"
                                src="@/assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
                            />
                        </a>
                    </div>
                    <div class="list-ksps">
                        <div class="list-item">
                            <img
                                class="list-ksps-check"
                                src="@/assets/images/checkmark_brand.svg"
                            />
                            <div class="list-item-body">
                                <strong class="ts-brand">{{
                                    $t("reason_to_use.reasons.1.title")
                                }}</strong>
                                <p class="ts-quiet">
                                    {{ $t("reason_to_use.reasons.1.description") }}
                                </p>
                            </div>
                        </div>
                        <div class="list-item" v-if="$i18n.locale == 'en'">
                            <img
                                class="list-ksps-check"
                                src="@/assets/images/checkmark_brand.svg"
                            />
                            <div class="list-item-body">
                                <strong class="ts-brand">{{
                                    $t("reason_to_use.reasons.2.title")
                                }}</strong>
                                <p class="ts-quiet">
                                    {{ $t("reason_to_use.reasons.2.description") }}
                                </p>
                            </div>
                        </div>
                        <div class="list-item">
                            <img
                                class="list-ksps-check"
                                src="@/assets/images/checkmark_brand.svg"
                            />
                            <div class="list-item-body">
                                <strong class="ts-brand">{{
                                    $t("reason_to_use.reasons.3.title")
                                }}</strong>
                                <p class="ts-quiet">
                                    {{ $t("reason_to_use.reasons.3.description") }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="wall-picture app-promo">
                    <div class="wall-picture-wrap">
                        <img :src="appPromoEn[$i18n.locale]" />
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { lazyLoadComponent } from "@/utils.js"

import appImageEn from "@/assets/images/app_in_white_en.png"
import appImageJa from "@/assets/images/app_in_white_ja.png"
import appPromoEn from "@/assets/images/app_promo_1_en.png"
import appPromoJa from "@/assets/images/app_promo_1_ja.png"

export default {
    meta() {
        return this.$generateMeta({})
    },

    i18n: {
        messages: {
            en: {
                meta: {
                    description:
                        "Enjoy excellence mobile internet with Soracom Mobile eSIM when traveling internationally. The eSIM technology helps travelers access internet in a smart and seamless way.",
                },
                title: "Activate your eSIM with Soracom Mobile and roam freely.",
                main_plot: `Choose a prepaid eSIM for locally-priced data service in any of 58 countries worldwide. Instant activation. No setup required. Works with Apple Pay.`,
                how_it_works: {
                    title: "How it works",
                    steps: {
                        1: {
                            title: `Download the app`,
                            description: `Search for "Soracom Mobile" on the App Store and download the app to your device.`,
                        },
                        2: {
                            title: `Choose an eSIM`,
                            description: `Browse the in-app store to select a region and data plan.`,
                        },
                        3: {
                            title: `Tap to install`,
                            description: `Once purchased, our app will install your eSIM profile. You're ready to go!`,
                        },

                        4: {
                            title: `Congratulations!`,
                            description: `That's it! Your prepaid data service will activate automatically in your selected destination.`,
                        },
                    },
                },
                coverage_info: {
                    title: "Works in Europe, North America, Asia & Oceania",
                    description: `Soracom offers prepaid eSIM for 58 countries worldwide, and the list keeps growing.`,
                    view_full_list: "VIEW FULL LIST",
                },
                all_you_need: {
                    title: "Could not be easier, works out of the box",

                    description: `Your device will be automatically configured once the eSIM is installed. No need to manually set anything up.`,
                    view_our_plans: "Getting Started",
                },
                reason_to_use: {
                    title: "So many reasons to opt for eSIM for your next trip",
                    reasons: {
                        1: {
                            title: "No tools required, no physical SIM to manage",
                            description: `When you're traveling, the last thing you need to worry about is where you left your SIM card. Keep your carrier SIM in place, leave your tools at home, and roam freely with your Dual SIM device.`,
                        },
                        2: {
                            title: "No waiting, no activation, and no worry",
                            description: `With Soracom Mobile there's no need to scan a QR code, register with a new provider, or activate a physical SIM card. Your Dual SIM device will automatically switch to Soracom Mobile prepaid data in your destination of choice.`,
                        },
                        3: {
                            title: "No packaging or waste",
                            description: `eSIM aren't just easier -- they're greener too! Everything is electronic, so you can travel light and leave no trace.`,
                        },
                    },
                },

                meta: {
                    title: "Prepaid Data eSIM for International Travel - Soracom Mobile",
                    description:
                        "Enjoy low-cost mobile internet with Soracom Mobile eSIM when traveling internationally. The eSIM technology helps travelers access internet in a smart and seamless way.",
                },
            },
            ja: {
                meta: {
                    description:
                        "ソラコム モバイルのeSIMを使えば海外旅行先でも手軽でスマートにインターネットを使うことができます。eSIMテクノロジーで海外での新しいデータ通信を体験しましょう！",
                },
                title: "eSIMデータ通信へのすべてが<br />1つのアプリで完結",
                main_plot: `世界58カ国のeSIMデータ通信を提供し、これまでの海外旅行や海外出張の悩みをスマートに解決。APNやローミング設定も一切不要、アプリのみでeSIMデータ通信の開始までサポートします。`,
                how_it_works: {
                    title: "ご利用方法",
                    steps: {
                        1: {
                            title: `アプリをダウンロード`,
                            description: `App StoreでSoracom Mobileと検索し、まずはアプリを入手してください。`,
                        },
                        2: {
                            title: `eSIMを選択`,
                            description: `アプリ内のStoreから地域とeSIMプランを選びます。`,
                        },
                        3: {
                            title: `ワンタップインストール`,
                            description: `決済完了後そのままアプリ内でeSIMを端末に追加することができます。`,
                        },

                        4: {
                            title: `完了です！`,
                            description: `これで準備OK。いつでも渡航先でモバイルデータ通信をご利用いただけます。`,
                        },
                    },
                },
                coverage_info: {
                    title: "4つの地域で利用可能",
                    description: `Soracom MobileのプリペイドeSIMはヨーロッパ、北米、アジア、オセアニアの合わせて58ヵ国に対応。今後さらに多くの地域・国に拡大します。`,
                    view_full_list: "eSIMリスト一覧",
                },
                all_you_need: {
                    title: "驚くほど手軽なeSIMのインストール",

                    description: `QRコードの用意やモバイル設定の変更をすることなく、アプリの中でeSIMのインストールは完結します。eSIMのメリットを存分に体験してください。`,
                    view_our_plans: "利用の流れ",
                },
                reason_to_use: {
                    title: "旅行時にeSIMを使う様々なメリット",
                    reasons: {
                        1: {
                            title: "特別なツールや物理SIMを扱う必要なし",
                            description: `旅行中、SIMカードを保管したり管理するのは面倒です。eSIMならいつものSIMカードはそのまま、道具も一切不要で、海外で気軽にインターネットを利用できます。`,
                        },
                        2: {
                            title: "待ち時間、アクティベーション、心配、すべて不要",
                            description: `With Soracom Mobile there's no need to scan a QR code, register with a new provider, or activate a physical SIM card. Your Dual SIM device will automatically switch to Soracom Mobile prepaid data in your destination of choice.`,
                        },
                        3: {
                            title: "パッケージやゴミが出ない",
                            description: `eSIMは便利なだけではありません。すべてがデジタルなので持ち歩くモノもなく、ゴミも出ません。`,
                        },
                    },
                },

                meta: {
                    title: "Prepaid Data eSIM for International Travel - Soracom Mobile",
                    description:
                        "Enjoy low-cost mobile internet with Soracom Mobile eSIM when traveling internationally. The eSIM technology helps travelers access internet in a smart and seamless way.",
                },
            },
        },
    },
    data() {
        return {
            total: 0,
            showMore: false,
            toggle: "first-time-user",
            appImage: {
                en: appImageEn,
                ja: appImageJa,
            },
            appPromoEn: {
                en: appPromoEn,
                ja: appPromoJa,
            },
        }
    },
    computed: {
        ...mapGetters({ countries: "data/topCountries", plans: "data/plans" }),
        countryLines() {
            const limit = Math.round(this.countries.length / 2) + 1
            return [
                this.countries.slice(0, limit),
                this.countries.slice(limit, this.countries.length),
            ]
        },
        ssr() {
            return typeof __PRERENDER != "undefined" && __PRERENDER.prerender
        },
    },
}
</script>
