const defaultConfig = {
    rootMargin: "260px",
    threshold: 0,
    load(element) {
        if (element.nodeName.toLowerCase() === "picture") {
            const img = document.createElement("img")
            if (isIE && element.getAttribute("data-iesrc")) {
                img.src = element.getAttribute("data-iesrc")
            }
            if (element.getAttribute("data-alt")) {
                img.alt = element.getAttribute("data-alt")
            }
            element.appendChild(img)
        }
        if (element.tagName.toLocaleLowerCase() == "img") {
            element.src = element.getAttribute("data-src")
        }
        if (element.tagName.toLocaleLowerCase() == "source") {
            element.setAttribute("srcset", element.getAttribute("data-src"))
        }
        if (element.getAttribute("data-background-image")) {
            element.style.backgroundImage = `url('${element.getAttribute(
                "data-background-image"
            )}')`
        }
        if (element.getAttribute("data-toggle-class")) {
            element.classList.toggle(element.getAttribute("data-toggle-class"))
        }
    },
    loaded() {},
}

const onIntersection = load => (entries, observer) => {
    for (const entry of entries) {
        if (entry.intersectionRatio > 0 || entry.isIntersecting) {
            observer.unobserve(entry.target)
            load(entry.target)
        }
    }
}

export default {
    install(Vue, options = {}) {
        const { root, rootMargin, threshold, load } = { ...defaultConfig, ...options }
        let observer

        if (!window.IntersectionObserver) {
            require("intersection-observer")
        }
        observer = new IntersectionObserver(onIntersection(load), {
            root,
            rootMargin,
            threshold,
        })
        Vue.directive("lazy", {
            bind(el, binding, vnode) {
                if (binding.value) {
                    if (!observer) {
                        load(el)
                        return
                    }
                    el.setAttribute("data-src", binding.value)
                    observer.observe(el)
                }
            },
            unbind(el) {
                if (observer) {
                    observer.unobserve(el)
                }
            },
        })
    },
}
