<template>
    <header class="site-header" role="banner">
        <div class="site-header-inner" v-click-outside="closeMenu">
            <div class="site-header-nav">
                <i18n-link :to="{ name: 'home' }">
                    <img
                        width="205"
                        class="site-logo"
                        src="@/assets/images/logo_SoracomMobile_B_wh.svg"
                    />
                </i18n-link>
                <a href="#" @click="menu = !menu" class="mobile-nav-trigger">Menu</a>
                <Navigation :active="menu" />
            </div>
        </div>
    </header>
</template>

<script>
import LanguageMenu from "@/components/LanguageMenu"
import Navigation from "@/components/Navigation"
export default {
    components: { Navigation },
    data() {
        return {
            menu: false,
            setupMenu: false,
            locked: false,
        }
    },
    methods: {
        closeMenu() {
            this.menu = false
        },
    },
    watch: {
        "$route.name": function() {
            this.menu = false
        },
    },
}
</script>
