<template>
    <nav :class="['site-nav', { 'is-active': active }]" id="menu">
        <div class="site-nav-item">
            <i18n-link class="menu-link" :to="{ name: 'pricing' }">
                <span>{{ $t("menu.pricing") }}</span>
            </i18n-link>
        </div>
        <div class="site-nav-item">
            <i18n-link class="menu-link" :to="{ name: 'getting-started' }">
                <span class="normal-case">{{ $t("menu.getting_started") }}</span>
            </i18n-link>
        </div>
        <div class="site-nav-item">
            <a class="menu-link" :href="$t('link.faq')" target="_blank" rel="noopener noreferrer">
                <span>{{ $t("menu.faq") }}</span>
            </a>
        </div>

        <LanguageMenu />
    </nav>
</template>

<script>
import LanguageMenu from "@/components/LanguageMenu"
export default {
    components: { LanguageMenu },

    props: {
        active: Boolean,
    },
    i18n: {
        messages: {
            en: {
                plans: "Plans",
                getting_started: "getting started",
                faq: "faq",
            },
        },
    },
}
</script>
