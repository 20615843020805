export default {
    lang: "en",
    meta: {
        default: {
            middle: "Prepaid Data eSIM for International Travel | Soracom Mobile",
            short: "Soracom Mobile",
            description:
                "Enjoy excellence mobile internet with Soracom Mobile eSIM when traveling internationally. The eSIM technology helps travelers access internet in a smart and seamless way.",
        },
    },
    label: {
        days: " | {n} day | {n} days",
    },
    button: {
        hide_privacy_banner: "Got It",
    },
    menu: {
        getting_started: "GETTING STARTED",
        pricing: "Pricing",
        about: "About",
        sct: "特商法に基づく表示",
        privacy: "Privacy Policy",
        terms: "Terms of Use",
        contact: "Contact Us",
        compatibility: "Compatibility",
        faq: "FAQ",
        cookie_policy: "Cookie Policy",
    },
    message: {
        privacy_policy_banner:
            "We use cookies and analysis tools to improve the friendliness of our website",
    },
    link: {
        app: "https://apps.apple.com/us/app/apple-store/id1498164804",
        faq: "https://support.soracommobile.com/hc/en-us",
    },
}
