<template>
    <transition appear :name="transitionName">
        <div class="Cookie" :class="[containerPosition, cookieTheme]" v-if="isOpen">
            <slot :accept="accept" :close="close" :open="open">
                <div class="Cookie__content">
                    <slot name="message">{{ message }}</slot>
                </div>
                <div class="Cookie__buttons">
                    <a
                        :target="target"
                        :href="buttonLink"
                        v-if="externalButtonLink"
                        :class="buttonClass"
                        >{{ buttonLinkText }}</a
                    >
                    <router-link :to="buttonLink" v-if="internalButtonLink" :class="buttonClass">{{
                        buttonLinkText
                    }}</router-link>
                    <button :class="'button button-dark'" @click="accept">{{ buttonText }}</button>
                </div>
            </slot>
        </div>
    </transition>
</template>

<script>
const STORAGE_TYPES = {
    local: "localStorage",
    cookies: "cookies",
}
export default {
    name: "VueCookieLaw",
    props: {
        buttonText: {
            type: String,
            default: "Got it!",
        },
        buttonLink: {
            type: [String, Object],
            required: false,
        },
        buttonLinkText: {
            type: String,
            default: "More info",
        },
        buttonLinkNewTab: {
            type: Boolean,
            default: false,
        },
        message: {
            type: String,
            default:
                "This website uses cookies to ensure you get the best experience on our website.",
        },
        theme: {
            type: String,
            default: "base",
        },
        /**
         * Cookie Container position
         * bottom, top
         * @type {Object}
         */
        position: {
            type: String,
            default: "bottom",
        },
        /**
         * Transition name has following possibilities
         * slideFromBottom
         * slideFromTop
         * fade
         * @type {Object}
         */
        transitionName: {
            type: String,
            default: "slideFromBottom",
        },
        buttonClass: {
            type: String,
            default: "Cookie__button",
        },
        storageName: {
            type: String,
            default: "cookie:accepted",
        },
    },
    data() {
        return {
            supportsLocalStorage: true,
            isOpen: false,
        }
    },
    computed: {
        containerPosition() {
            return `Cookie--${this.position}`
        },
        cookieTheme() {
            return `Cookie--${this.theme}`
        },
        externalButtonLink() {
            return typeof this.buttonLink === "string" && this.buttonLink.length
        },
        internalButtonLink() {
            return (
                typeof this.buttonLink === "object" &&
                this.buttonLink != null &&
                Object.keys(this.buttonLink).length
            )
        },
        target() {
            return this.buttonLinkNewTab ? "_blank" : "_self"
        },
    },
    created() {
        if (this.storageType === STORAGE_TYPES.local) {
            // Check for availability of localStorage
            try {
                const test = "__vue-cookielaw-check-localStorage"
                window.localStorage.setItem(test, test)
                window.localStorage.removeItem(test)
            } catch (e) {
                console.info("Local storage is not supported, falling back to cookie use")
                this.supportsLocalStorage = false
            }
        }
        if (!this.getVisited() === true) {
            this.isOpen = true
        }
    },
    methods: {
        setVisited() {
            localStorage.setItem(this.storageName, true)
        },
        getVisited() {
            return localStorage.getItem(this.storageName)
        },
        accept() {
            this.setVisited()
            this.isOpen = false
            this.$emit("accept")
        },
        close() {
            this.isOpen = false
        },
        open() {
            if (!this.getVisited() === true) {
                this.isOpen = true
            }
        },
    },
}
</script>

<style lang="scss">
@import "~@nextindex/next-scss/next-scss.scss";
.Cookie {
    position: fixed;
    overflow: hidden;
    box-sizing: border-box;
    z-index: 9999;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-direction: column;
    > * {
        margin: rem(15) 0;
        align-self: center;
    }
    @include media($sm-up) {
        flex-flow: row;
        > * {
            margin: 0;
        }
    }
}
.Cookie--top {
    top: 0;
    left: 0;
    right: 0;
}
.Cookie--bottom {
    bottom: 0;
    left: 0;
    right: 0;
}
.Cookie__buttons {
    display: flex;
    flex-direction: column;
    > * {
        margin: rem(5) 0;
    }
    @include media($sm-up) {
        flex-direction: row;
        > * {
            margin: 0 rem(15);
        }
    }
}
.Cookie__button {
    cursor: pointer;
    align-self: center;
    white-space: nowrap;
}
@mixin generateTheme(
    $theme,
    $backgroundColor,
    $fontColor,
    $buttonBackgroundColor,
    $buttonFontColor: #fff,
    $buttonRadius: 0
) {
    .Cookie--#{$theme} {
        background: $backgroundColor;
        color: $fontColor;
        padding: 1.25em;
    }
}
@include generateTheme("base", #353535, #fff, #97d058);
.slideFromTop-enter,
.slideFromTop-leave-to {
    transform: translate(0px, -12.5em);
}
.slideFromTop-enter-to,
.slideFromTop-leave {
    transform: translate(0px, 0px);
}
.slideFromBottom-enter,
.slideFromBottom-leave-to {
    transform: translate(0px, 12.5em);
}
.slideFromBottom-enter-to,
.slideFromBottom-leave {
    transform: translate(0px, 0px);
}
.slideFromBottom-enter-active,
.slideFromBottom-leave-active,
.slideFromTop-enter-active,
.slideFromTop-leave-active {
    transition: transform 0.4s ease-in;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
