<template>
    <div id="cookie-banner">
        <cookie-law
            v-if="showCookieBanner && !ssr"
            v-on:accept="accept"
            :button-text="$t('button.hide_privacy_banner')"
        >
            <div slot="message">
                {{ $t("message.privacy_policy_banner") }}
                <i18n-link class="ts-white ts-underline" :to="{ name: 'cookie-policy' }">{{
                    $t("menu.cookie_policy")
                }}</i18n-link>
            </div>
        </cookie-law>
    </div>
</template>

<script>
import CookieLaw from "@/components/CookieLaw.vue"
import { mapActions } from "vuex"
export default {
    components: { CookieLaw },
    data() {
        return {
            showCookieBanner: false,
        }
    },
    computed: {
        ssr() {
            return typeof __PRERENDER != "undefined" && __PRERENDER.prerender
        },
    },
    methods: {
        ...mapActions({
            isEU: "data/isEU",
        }),
        accept() {
            this.showCookieBanner = false
        },
    },
    async mounted() {
        if (!this.ssr) {
            let isEurope = await this.isEU()

            if (isEurope.isEU) {
                this.showCookieBanner = true
            }
        }
    },
}
</script>
