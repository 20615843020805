export default {
    lang: "ja",
    meta: {
        default: {
            middle: "グローバルeSIMデータ通信サービス | ソラコム モバイル",
            short: "ソラコム モバイル",
            description:
                "ソラコム モバイルのeSIMを使えば海外旅行先でも手軽でスマートにインターネットを使うことができます。eSIMテクノロジーで海外での新しいデータ通信を体験しましょう！",
        },
    },
    label: {
        days: " | {n} 日 | {n} 日",
    },
    menu: {
        getting_started: "eSIM利用の流れ",
        pricing: "料金",
        about: "About",
        sct: "特商法に基づく表示",
        privacy: "個人情報保護方針",
        terms: "利用規約",
        contact: "問い合わせ",
        compatibility: "動作保証端末",
        faq: "よくある質問",
    },
    link: {
        app: "https://apps.apple.com/jp/app/apple-store/id1498164804",
        faq: "https://support.soracommobile.com/hc/ja",
    },
}
