<template>
    <div class="checkbox">
        <div class="input">
            <input
                type="checkbox"
                ref="check"
                class="checkbox"
                :value="value"
                v-bind="$attrs"
                v-on="listeners"
                :id="$attrs.name"
            />
            <label :for="$attrs.name" class="check">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    style=" fill:#000000;"
                >
                    <g id="surface1">
                        <path
                            style=" "
                            d="M 22.566406 4.730469 L 20.773438 3.511719 C 20.277344 3.175781 19.597656 3.304688 19.265625 3.796875 L 10.476563 16.757813 L 6.4375 12.71875 C 6.015625 12.296875 5.328125 12.296875 4.90625 12.71875 L 3.371094 14.253906 C 2.949219 14.675781 2.949219 15.363281 3.371094 15.789063 L 9.582031 22 C 9.929688 22.347656 10.476563 22.613281 10.96875 22.613281 C 11.460938 22.613281 11.957031 22.304688 12.277344 21.839844 L 22.855469 6.234375 C 23.191406 5.742188 23.0625 5.066406 22.566406 4.730469 Z "
                        />
                    </g>
                </svg>
            </label>
        </div>
        <label :for="$attrs.name" class="text">
            <slot />
        </label>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        value: {},
        disabled: { type: Boolean, required: false },
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                input: event => {
                    if (!this.disabled) {
                        this.$emit("input", event.target.checked)
                    }
                },
            }
        },
    },
    watch: {
        value(value) {
            if (value) {
                this.$refs.check.checked = true
            } else {
                this.$refs.check.checked = false
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.checkbox {
    display: flex;

    align-items: center;
}
.input {
    margin-right: 10px;
    flex: 0 1 30px;
}
input {
    position: absolute;
    opacity: 0;
    margin-right: 10px;

    &:checked + label svg path {
        fill: #fff;
        stroke: #fff;
    }
    &:checked + label {
        stroke: #fff;
        border: 2px solid black;
        background-color: black;
    }
}

label.check {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #999;
    width: 23px;
    height: 23px;
    border-radius: 100%;
    cursor: pointer;
    text-align: center;
    transition: all 0.2s ease;

    &:active {
        transform: scale(1.05);
        border-radius: 100%;
    }

    svg {
        width: 15px;
        height: 15px;

        pointer-events: none;
        path {
            fill: #999;
            stroke: #999;
            stroke-linecap: round;
            stroke-linejoin: round;
            transition: all 350ms cubic-bezier(1, 0, 0.37, 0.91);
        }
    }
}
</style>
